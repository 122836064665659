import PageComponent from '../component/page-component';


class Toggler extends PageComponent {

	constructor({
		root,
		element,
		eventType = 'click',
		toggledClass = 'toggled',
		preventDefault = true,
		blurOnToggle = true,
		forAttribute = 'id',
		targetAction = 'toggle',
		dynamicTarget = false,
		beforeToggleEvent = 'toggler:beforetoggle',
		toggleEvent = 'toggler:toggle',
		enabledByCss = false,
		cssEnabledProperty = 'togglerEnabled',
		disabledClass = 'disabled',
		options = {
			invertValue: false
		},
		toggledOptions = {}
	}) {
		super({root: root, element: element});
		this.defaults.eventType = eventType;
		this.defaults.toggledClass = toggledClass;
		this.defaults.preventDefault = preventDefault;
		this.defaults.blurOnToggle = blurOnToggle;
		this.defaults.forAttribute = forAttribute;
		this.defaults.targetAction = targetAction;
		this.defaults.dynamicTarget = dynamicTarget;
		this.defaults.beforeToggleEvent = beforeToggleEvent;
		this.defaults.toggleEvent = toggleEvent;
		this.defaults.enabledByCss = enabledByCss;
		this.defaults.cssEnabledProperty = cssEnabledProperty;
		this.defaults.options = options;
		this.defaults.toggledOptions = toggledOptions;
		this.defaults.disabledClass = disabledClass;
		this.targetSelector = null;
		this.targets = null;
		this.toggled = null; // will be a boolean after prepare()
		this.busy = false;
		this.enabled = true;
	}


	prepare() {
		const data = this.dataAttr().getAll();
		this.options = data.options;
		this.toggledOptions = data.toggledOptions;
		this.eventType = data.eventType;
		this.beforeToggleEvent = data.beforeToggleEvent;
		this.toggleEvent = data.toggleEvent;
		this.disabledClass = data.disabledClass;

		this.isButton = this.element.tagName.toLowerCase() === 'button';

		// if the for attribute is not available, it fallbacks to the href anchor value
		if ('for' in data) {
			this.targetSelector = this.dataSelector(data.forAttribute, data.for);
		} else if (this.element.hasAttribute('href')) {
			const href = this.element.getAttribute('href');
			if (href.indexOf('#') === 0) {
				this.targetSelector = href;
			}
		}

		this.toggled = this.classList().contains(data.toggledClass);
		this.enabled = (this.isButton && !this.element.disabled) || !this.classList().contains(this.disabledClass);

		this.listeners.toggle = this.events.on(this.element, this.eventType, this.onToggle.bind(this));
	}


	onToggle(event) {
		const data = this.dataAttr().getAll();
		if (data.preventDefault) {
			event.preventDefault();
		}
		if (data.blurOnToggle) {
			this.element.blur();
		}
		if (!data.enabledByCss || this.cssData().get(data.cssEnabledProperty, true)) {
			this.toggle();
		}
	}


	toggle(value = null, options = {}, toggledOptions = {}) {
		const data = this.dataAttr().getAll();
		const originalValue = value;
		return Promise.resolve().then(() => {
			if (this.busy) {
				return false;
			}
			value = (value === null ? !this.toggled : !!value);
			if (value === this.toggled) {
				return false;
			} else {
				this.busy = true;
				options = Object.assign({}, this.options, options);
				toggledOptions = Object.assign({}, this.toggledOptions, toggledOptions);
				return this.toggling(originalValue, value, options, toggledOptions, data);
			}
		}).then((complete) => {
			if (complete) {
				this.busy = false;
				this.events.trigger(this.element, data.toggleEvent, {component: this, toggled: this.toggled});
			}
		});
	}


	toggling(originalValue, value, options, toggledOptions, data) {
		const beforeEvent = this.events.trigger(this.element, data.beforeToggleEvent, {component: this, toggled: this.toggled, intention: value});
		if (beforeEvent.defaultPrevented) {
			this.busy = false;
			return false;
		}
		this.classList(this.element).toggle(data.toggledClass, value);
		this.element.setAttribute('aria-expanded', value);
		this.toggled = value;
		if (this.targetSelector) {
			const targets = this.getToggledComponents();
			const toggledValue = options.invertValue ? !value : originalValue;
			const promises = targets.map((target) => target[data.targetAction](toggledValue, toggledOptions));
			if (promises.length) {
				return Promise.all(promises).then(() => true);
			}
		}
		return true;
	}


	isBusy() {
		return this.busy;
	}


	isToggled() {
		return this.toggled;
	}


	getToggleEventType() {
		return this.toggleEvent;
	}


	getBeforeToggleEventType() {
		return this.beforeToggleEvent;
	}


	getToggledComponents() {
		if (this.targetSelector && (this.targets === null || this.dataAttr().get('dynamicTarget'))) {
			this.targets = this.components.queryComponents(this.root, this.targetSelector);
		}

		return this.targets;
	}


	disable() {
		if (this.enabled) {
			this.enabled = false;
			if (this.isButton) {
				this.element.disabled = true;
			} else {
				this.classList().add(this.disabledClass);
			}
		}
	}


	enable() {
		if (!this.enabled) {
			this.enabled = true;
			if (this.isButton) {
				this.element.disabled = false;
			} else {
				this.classList().remove(this.disabledClass);
			}
		}
	}


	toggleEnable(value = null) {
		if (value === null) {
			value = !this.enabled;
		}
		const method = value ? 'enable' : 'disable';
		this[method]();
	}


	isEnabled() {
		return this.enabled;
	}

}

export default Toggler;
