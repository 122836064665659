import PageComponent from '../../common/component/page-component';
import apiMixin from '../../common/api/api-mixin';


class FacePattern extends apiMixin(PageComponent) {
	constructor({
		root,
		element,
		updateInterval = 5000,
		apiActionAttribute = 'apiAction',
		patternIdAttribute = 'patternId',
		patternSrcsetAttribute = 'pngSrcset',
		fadeOutClass = 'fadeOut',
		asyncLoadedClass = 'loaded',
		srcsetAttr = 'srcset',
	}) {
		super({root: root, element: element});
		this.updateInterval = updateInterval;
		this.apiActionAttribute = apiActionAttribute;
		this.patternIdAttribute = patternIdAttribute;
		this.patternSrcsetAttribute = patternSrcsetAttribute;
		this.fadeOutClass = fadeOutClass;
		this.asyncLoadedClass = asyncLoadedClass;
		this.srcsetAttr = srcsetAttr;
	}


	prepare() {
		this.apiAction = this.dataAttr().get(this.apiActionAttribute);
		this.patternId = this.dataAttr().getRaw(this.patternIdAttribute);
	}


	start() {
		this.resetTimer();
	}


	stop() {
		clearTimeout(this.timeout);
	}


	destroy() {
		clearTimeout(this.timeout);
	}


	resetTimer() {
		clearTimeout(this.timeout);
		this.timeout = setTimeout(this.checkForUpdate.bind(this), this.updateInterval);
	}


	checkForUpdate() {
		this.api.execute(this.apiAction).then((response) => {
			if (response.isSuccess()) {
				if (response.output.pattern.id !== this.patternId) {
					this.patternId = response.output.pattern.id;
					this.loadFile(response.output.pattern);
				} else {
					this.resetTimer();
				}
			} else {
				this.resetTimer();
			}
		});
	}


	loadFile(pattern) {
		const fig = this.element.querySelector('figure');
		const copyFig = fig.cloneNode(true);
		const copyImg = copyFig.querySelector('img');

		// 'reset' cloned component ...
		this.classList(copyImg).remove(this.asyncLoadedClass);
		this.dataAttr(copyImg).set(this.srcsetAttr, pattern[this.patternSrcsetAttribute]);
		fig.parentNode.insertBefore(copyFig, fig);
		const imgComp = this.components.getComponent(copyFig, 'AsyncPicture');
		imgComp.load().then(() => {
			this.classList(fig).add(this.fadeOutClass);
			return this.onTransitionEnd(fig);
		}).then(() => {
			fig.remove();
			this.resetTimer();
		});
	}
}

export default FacePattern;
