class Request {
    constructor({api, command, params, options}) {
		this.api = api;
		this.command = command;
		this.params = params;
		this.options = options;
		this.remoteCall = null;
		this.pending = true;
		this.aborted = false;
		this.response = undefined;
		this.error = undefined;

		this.resolveTrigger = null;
		this.rejectTrigger = null;
		this.action = null;
	}


	setTriggers(resolve, reject) {
		this.resolveTrigger = resolve;
		this.rejectTrigger = reject;
		return this;
	}


	setAction(action) {
		this.action = action;
		return this;
	}


	setRemoteCall(remoteCall) {
		this.remoteCall = remoteCall;
		this.pending = false;
		return this;
	}


	execute() {
		this.resetResponse();
		this.action();
		return this;
	}


	abort() {
		if (!this.aborted && this.remoteCall) {
			this.remoteCall.abort();
			this.remoteCall = null;
			this.aborted = true;
		}
		return this;
	}


	setResponse(response) {
		this.response = response;
		return this;
	}


	setError(error) {
		this.error = error;
		return this;
	}


	isDummy() {
		return !!(this.options.dummyResponse || this.options.dummyError);
	}


	isPending() {
		return this.pending;
	}


	isFailed() {
		return this.error !== undefined;
	}


	processResponse() {
		if (!this.aborted) {
			const response = (this.options.dummyResponse ? this.options.dummyResponse : this.response);
			const error = (this.options.dummyError ? this.options.dummyError : this.error);
			if (response !== undefined) {
				this.resolveTrigger(response);
			} else if (error !== undefined) {
				this.rejectTrigger(error);
			}
			this.remoteCall = null;
		}
		return this;
	}


	forceResponseToError() {
		const response = (this.options.dummyResponse ? this.options.dummyResponse : this.response);
		if (response !== undefined) {
			this.rejectTrigger(response);
		}
		this.remoteCall = null;
		return this;
	}


	resetResponse() {
		this.remoteCall = null;
		this.pending = true;
		this.aborted = false;
		this.response = undefined;
		this.error = undefined;
		return this;
	}

}

export default Request;
