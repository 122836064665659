import CollapsingHeader from '../../common/widget/Collapsing-Header';
import {getViewportHeight} from '../../common/utils/size';
import {getScrollTop} from '../../common/utils/get-scroll';


class U14CollapsingHeader extends CollapsingHeader {

	constructor({
		root,
		element,
		collapsedClass = 'collapsed',
		scrolledClass = 'scrolled'
	}) {
		super({root: root, element: element, collapsedClass: collapsedClass});
		this.defaults.scrolledClass = scrolledClass;

		this.viewportHeight = 0;
		this.scrolled = false;
	}


	prepare() {
		this.scrolledClass = this.dataAttr().get('scrolledClass');
		super.prepare();
	}


	updateSize() {
		this.viewportHeight = getViewportHeight();
		super.updateSize();
	}


	updateState() {
		const scrollPos = getScrollTop();
		if (scrollPos !== this.previousScrollPos) {
			this.classList().toggle(this.scrolledClass, scrollPos >= this.viewportHeight);
		}
		super.updateState();
	}

}


export default U14CollapsingHeader;
