import domMixin from '../dom/dom-mixin';
import {isString} from '../utils/types';


class SectionSlots extends domMixin() {

    constructor({root = document.body}) {
		super();
		this.slots = new Map();
		this.sections = new Map();
	}


	injectSectionSlotFactory(factory) {
		this.factory = factory;
	}


	get(name) {
        if (this.slots.has(name)) {
            return this.slots.get(name);
		}
		const slotClass = this.factory.has(name) ? name : '';
		const slot = this.factory.newInstance(slotClass, {name: name, root: this.root});
		this.slots.set(name, slot);
		return slot;
	}


	fillSection(name, content, pageBound = false) {
		if (!isString(content) && ('content' in content)) {
			pageBound = ('pageBound' in content ? !!content.pageBound : pageBound);
			content = content.content;
		}
		const slot = this.get(name);
		slot.fill(content, pageBound);
		const slotElement = slot.getElement();
		if (slotElement) {
			this.events.trigger(slotElement, 'section:change', {name: name, content: content, slot: slot});
		}
		return this;
	}


	emptySection(name) {
		return this.fillSection(name, '');
	}


	emptyPageBoundSlots() {
		for (const [name, slot] of this.slots) {
			if (slot.isPageBound()) {
				this.emptySection(name, '');
			}
		}
		return this;
	}

}


export default SectionSlots;
