import {isString} from '../utils/types';
import NavigationTracker from './navigation-tracker';


class GaGtagNavigationTracker extends NavigationTracker {


	init() {
		super.init();
		// eslint-disable-next-line piggyback/no-restricted-global-extend
		this.tracker = ('gtag' in window ? window.gtag : null);
		if (this.tracker) {
			// eslint-disable-next-line piggyback/no-restricted-global-extend
			this.trackingId = window.gtagTrackingId;
		}
	}


	doTrack(url, title) {
		if (this.tracker) {
			console.log('tracking', url, title);
			this.tracker('config', this.trackingId, {
				page_title: title,
				page_location: url
			});
			// gtag('config', 'GA_TRACKING_ID', {
			// 	'page_title' : 'homepage',
			// 	'page_path': '/home'
			//   });
		} else {
			console.log('page change:', url, title);
		}
	}


	doTrackEvent(category, action = null, name = null, value = null) {
		const values = {};
		if (action === null) {
			action = category;
		} else {
			if (!isString(action)) {
				if ('name' in action) {
					values.event_label = action.name;
					if ('value' in action) {
						values.value = action.value;
					}
				}
				action = action.action;
			} else {
				if (name !== null) {
					values.event_label = name;
					if (value !== null) {
						values.value = value;
					}
				}
			}
			values.event_category = category;
		}

		if (this.tracker) {
			this.tracker('event', action, values);
		} else {
			console.log('tracking event', action, values);
		}
	}

}


export default GaGtagNavigationTracker;
