import MenuToggler from './menu-toggler';
import U14CollapsingHeader from './u14-collapsing-header';

export default (di) => {
	di
		.setType({type: MenuToggler, name: 'MenuToggler', parent: 'PageComponent', params: {
				mediaQuery: di.lazyValue('menuMediaQuery', 'fail')
			}
		})
		.setType({type: U14CollapsingHeader, name: 'U14CollapsingHeader', parent: 'CollapsingHeader', params: {
				mediaQuery: di.lazyValue('menuMediaQuery', 'fail')
			}
		})
	;
};
