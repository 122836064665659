const notificationsMixin = (Base = null) => class extends Base {

	injectNotifications(notifications) {
		this.notifications = notifications;
	}


	notify(notifications) {
		return this.notifications.notify(notifications);
	}

};


export default notificationsMixin;
