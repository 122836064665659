import notificationsMixin from '../notifications/notifications-mixin';
import dialogMixin from '../dialog/dialog-mixin';
import localeMixin from '../locale/locale-mixin';


class ApiListener extends localeMixin(notificationsMixin(dialogMixin())) {

	constructor({events}) {
		super();
		this.events = events;
		// listen to api calls, looking for errors
		this.events.on(document, 'api:complete api:error', this.onApiCallEnd.bind(this));
	}


	onApiCallEnd(event) {
		if (event.defaultPrevented) {
			return;
		}
		const request = event.detail.request;
		if (event.type === 'api:complete') {
			this.onComplete(event, request, request.response);
		} else {
			this.onError(event, request, request.error);
		}
	}


	// to be overriden
	onComplete(event, request, response) {}

	// to be overriden
	onError(event, request, response) {}
}

export default ApiListener;
