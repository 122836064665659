import commonConfig from './common';

import apiConfig from '../api/_config';
import appConfig from '../app/_config';
import bookingConfig from '../booking/_config';
import contextConfig from '../context/_config';
import facePatternConfig from '../face-pattern/_config';
import menuConfig from '../menu/_config';
import pageConfig from '../page/_config';


const config = (di) => {
	commonConfig(di);

	apiConfig(di);
	appConfig(di);
	bookingConfig(di);
	contextConfig(di);
	facePatternConfig(di);
	menuConfig(di);
	pageConfig(di);

	const prefix = 'u14-';
	const classPrefix = 'u14-js-';

	di
		.setValue('dom/dataAttrPrefix', prefix)
		.setValue('dom/classPrefix', classPrefix)
		.setValue('dom/baseClassPrefix', prefix)
		;

		const bodyData = di.get('dom/dataAttrFactory')({element: document.body});
		const baseUrl = bodyData.get('baseUrl');
		di
			.setValue('baseUrl', baseUrl)
			.setValue('api/baseUrl', bodyData.get('apiUrl'))
			.setValue('google/mapApiUrl', bodyData.get('googleMapApiUrl', ''))
			.setValue('google/mapApiKey', bodyData.get('googleMapApiKey', ''))
			.setParam('PageLoader', 'baseUrl', baseUrl)
			.setParam('HashScroller', 'duration', 0.5)
			.setParam('Navigation', 'navigateSameUrl', true)
	;
};


export default config;
