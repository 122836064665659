import domMixin from '../dom/dom-mixin';
import pageConstants from './_constants';


class SectionSlot extends domMixin() {

    constructor({name, root}) {
		super();
		this.name = name;
		this.root = root;
		this.pageBound = false;
    }


    getName() {
        return this.name;
    }


    getElement() {
        if (!this.element || !document.body.contains(this.element)) {
            this.element = document.querySelector(this.dataSelector(pageConstants.sectionSlotAttribute, this.name));
        }
        return this.element;
    }


	fill(content, pageBound = false) {
		const element = this.getElement();
		if (element) {
			if (content instanceof Element) {
				element.innerHTML = '';
				element.appendChild(content);
			} else {
				element.innerHTML = content;
			}
		}
		this.pageBound = pageBound;
		return this;
	}


	empty() {
		const element = this.getElement();
		if (element) {
			element.innerHTML = '';
		}
	}


	isPageBound() {
		return this.pageBound;
	}

}


export default SectionSlot;
